/* eslint-disable camelcase */
import client from '../utils/client';

const saveContext = async (contest_id) => {
  const url = `/contests/${contest_id}/user`;
  try {
    const response = await client().put(url);
    const { data } = response;
    return data;
  } catch (err) {
    return err;
  }
};

// eslint-disable-next-line import/prefer-default-export
export { saveContext };
