import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import {
  AppBar,
  Box,
  Container,
  Divider,
  Hidden,
  IconButton,
  MenuItem,
  MenuList,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AvatarMenuItems from '../AvatarMenu/AvatarMenuItems';
import Link from '../../common/Link';
import SpotlightLogo from '../../common/SpotlightLogo';
import routes from '../../../routes';
import MyNewsIcon from '../../common/MyNewsIcon/MyNewsIcon';
import ContestIcon from '../../Promotions/ContestIcon';
import ExploreIcon from '../../common/ExploreIcon/ExploreIcon';
import MyLibraryIcon from '../../common/MyLibraryIcon/MyLibraryIcon';
import MyStatsIcon from '../../common/MyStatsIcon/MyStatsIcon';
import { useQuery } from '../../../hooks/useQuery';
import { getNotificationsCount } from '../../../actions/notifications';

const styles = makeStyles((theme) => ({
  appBarRoot: {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0 3px 6px rgba(0, 0, 0, .16)',
    overflowY: 'hidden',
    background: 'red',
  },
  navContainerRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '100%',
  },
  logo: {
    flex: '0 1 auto',
    marginRight: 42,
    verticalAlign: 'middle',
    cursor: 'pointer',
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      marginRight: 20,
    },
  },
  tabsFlexContainer: {
    flex: '0 1 auto',
  },
  tabButtonRoot: {
    fontSize: '18px',
    lineHeight: '24px',
    padding: '18px 15px',
    '& > .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  menuIconMobileRoot: {
    color: theme.palette.text.primary,
    marginTop: 6,
    marginBottom: 6,
    marginLeft: 'auto',
  },
  menuBoxIconMobileRoot: {
    display: 'flex',
  },
  menuBoxIconDesktopRoot: {
    marginLeft: 'auto',
    display: 'flex',
  },
  menuIconDesktopRoot: {
    padding: 4,
    verticalAlign: 'middle',
  },
  itemRoot: {
    fontSize: (menu) => (menu.mobileMenuOpen ? 18 : 14),
    fontWeight: 500,
    paddingTop: 10,
    paddingBottom: 10,
  },
  itemGutters: {
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    flexDirection: (menu) => (menu.mobileMenuOpen ? 'row' : 'column'),
  },
  dividerRoot: {
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 14,
    marginTop: 14,
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuIconLogoBox: {
    display: 'flex',
    alignItems: 'center',
  },
  mobileMenuItems: {
    height: (menu) => (menu.mobileMenuOpen ? '93vh' : '100vh'),
    width: (menu) => (menu.mobileMenuOpen ? '350px' : '100px'),
    background: theme.palette.background.default,
    position: 'fixed',
    zIndex: 10,
    overflowY: (menu) => (menu.mobileMenuOpen ? 'scroll' : 'hidden'),
    [theme.breakpoints.down('sm')]: {
      display: (menu) => (menu.mobileMenuOpen ? 'block' : 'none'),
      height: '88vh !important',
    },
  },
}));

const getTabIndex = (currentPathname) => {
  if (currentPathname.startsWith(routes.news.main)) {
    return 0;
  } if (currentPathname === routes.discover) {
    return 1;
  } if (currentPathname === routes.myLibrary) {
    return 2;
  } if (currentPathname === routes.myStats) {
    return 3;
  }
  return false;
};

const NavigationBar = ({ user, history, updatePaddingLeft }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const darkMode = localStorage.getItem('darkMode') === 'true';
  const selectedTabIndex = useMemo(() => (
    getTabIndex(history.location.pathname)
  ), [history.location.pathname]);

  const iconColorMyNews = darkMode ? (selectedTabIndex === 0 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 0 ? '#000000' : '#8c8b91');
  const iconColorExplore = darkMode ? (selectedTabIndex === 1 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 1 ? '#000000' : '#8c8b91');
  const iconColorMyLibrary = darkMode ? (selectedTabIndex === 2 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 2 ? '#000000' : '#8c8b91');
  const iconColorMyStats = darkMode ? (selectedTabIndex === 3 ? '#ffffff' : '#8c8b91') : (selectedTabIndex === 3 ? '#000000' : '#8c8b91');
  const notificationsCountQuery = useQuery('notificationsCount', getNotificationsCount, {
    variables: [user?.profile?.id],
  });
  const notificationsCount = notificationsCountQuery.data;

  const classes = styles({ mobileMenuOpen });

  useEffect(() => {
    if (!updatePaddingLeft) return;
    if (mobileMenuOpen) updatePaddingLeft(350);
    else updatePaddingLeft(100);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobileMenuOpen]);

  return (
    <>
      <AppBar
        position="sticky"
        classes={{ root: classes.appBarRoot }}
      >
        <Container classes={{ root: classes.navContainerRoot }}>
          <Box className={classes.menuIconLogoBox}>
            {/* Mobile Menu */}
            <Box className={classes.menuBoxIconMobileRoot} style={{ display: mobileMenuOpen ? 'none' : 'block' }}>
              <IconButton
                classes={{ root: classes.menuIconMobileRoot }}
                onClick={() => setMobileMenuOpen(true)}
              >
                <MenuIcon />
              </IconButton>
            </Box>

            <Box className={classes.menuBoxIconMobileRoot} style={{ display: !mobileMenuOpen ? 'none' : 'block' }}>
              <IconButton
                classes={{ root: classes.menuIconMobileRoot }}
                onClick={() => setMobileMenuOpen(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>

            <Link href={routes.news.main}>
              {/* Desktop Menu */}
              <Hidden mdDown>
                <SpotlightLogo
                  className={classes.logo}
                  header
                  height={46}
                />
              </Hidden>

              {/* Mobile Menu */}
              <Hidden lgUp>
                <SpotlightLogo
                  small={user?.profile?.current_contest}
                  className={classes.logo}
                  header
                  height={46}
                />
              </Hidden>
            </Link>
          </Box>

          {/* Mobile Menu */}
          <Box>
            {user?.profile?.current_contest && (
              <ContestIcon mobile user={user} />
            )}
          </Box>
        </Container>
      </AppBar>
      <div className={classes.mobileMenuItems}>
        <MenuList>
          <Link to={routes.news.main} classes={{ root: classes.link }}>
            <MenuItem
              classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
              onClick={() => setMobileMenuOpen(false)}
            >
              <MyNewsIcon color={iconColorMyNews} mobile={mobileMenuOpen} />
              MyNews
            </MenuItem>
          </Link>
          <Link to={routes.discover} classes={{ root: classes.link }}>
            <MenuItem
              classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
              onClick={() => setMobileMenuOpen(false)}
            >
              <ExploreIcon color={iconColorExplore} mobile={mobileMenuOpen} />
              Search
            </MenuItem>
          </Link>
          <Link to={routes.myLibrary} classes={{ root: classes.link }}>
            <MenuItem
              classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
              onClick={() => setMobileMenuOpen(false)}
            >
              <MyLibraryIcon color={iconColorMyLibrary} mobile={mobileMenuOpen} />
              Your Library
            </MenuItem>
          </Link>
          <Link to={routes.myStats} classes={{ root: classes.link }}>
            <MenuItem
              classes={{ root: classes.itemRoot, gutters: classes.itemGutters }}
              onClick={() => setMobileMenuOpen(false)}
            >
              <MyStatsIcon color={iconColorMyStats} mobile={mobileMenuOpen} />
              MyStats
            </MenuItem>
          </Link>
          {mobileMenuOpen && (
            <>
              <Divider classes={{ root: classes.dividerRoot }} />
              <AvatarMenuItems
                user={user}
                s
                notificationsCount={notificationsCount}
              />
            </>
          )}
        </MenuList>
      </div>
    </>
  );
};

NavigationBar.propTypes = {
  user: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  updatePaddingLeft: PropTypes.func,
};

export default withRouter(NavigationBar);
