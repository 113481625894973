import { Box, Container, InputAdornment, Link, makeStyles, TextField, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { format as formatDate } from 'date-fns';
import { toast } from 'react-toastify';
import { getContests } from '../../actions/promotions';
import { useQuery } from '../../hooks/useQuery';
import routes from '../../routes';
import ContestFormLink from './ContestFormLink';

const styles = makeStyles((theme) => ({
  containerRoot: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  banner: {
    width: '100%',
    marginBottom: 20,
    marginTop: 21,
  },
  containerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  right: {
    width: 500,
    background: theme.palette.background.paper,
    padding: 20,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      marginTop: 20,
    },
  },
  left: {
    width: 620,
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  entries: {
    font: 'normal normal normal 24px/32px Roboto',
    letterSpacing: 1.71,
    margin: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  title: {
    font: 'normal normal bold 32px/43px Roboto',
    letterSpacing: 2.29,
    color: theme.palette.text.primary,
    margin: '10px 0px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  title2: {
    font: 'normal normal bold 18px/24px Roboto',
    letterSpacing: 1.29,
    color: theme.palette.text.primary,
    margin: '10px 0px',
  },
  text: {
    font: 'normal normal normal 16px/20px Roboto',
    letterSpacing: 0,
    color: theme.palette.text.primary,
  },
  description: {
    font: 'normal normal normal 16px/22px Roboto',
    letterSpacing: 0,
    color: theme.palette.text.primary,
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'right',
    alignContent: 'center',
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'left',
    },
  },
  share: {
    font: 'normal normal medium 18px/28px Roboto !important',
    color: theme.palette.text.primary,
    marginRight: 20,
    alignContent: 'center',
  },
  fileCopyIcon: {
    cursor: 'pointer',
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingLeft: 10,
  },
  backButtonLabel: {
    fontSize: 16,
    lineHeight: '24px',
    marginLeft: 10,
    display: 'block',
  },
}));

const ContestDetail = ({ match, webView = false }) => {
  const classes = styles();
  const inputRef = useRef(null);

  // eslint-disable-next-line react/prop-types
  const { contestId } = match.params;

  const contestsData = useQuery('getContests', getContests, {
    variables: [contestId],
  });
  const contest = contestsData?.data?.data?.shift();

  const enterDate = contest?.enter_by_date && formatDate(new Date(`${contest?.enter_by_date} 00:00:00`), 'MMMM d, u');

  return (
    <>
      <Box>
        <Link
          href={webView ? routes.contestsWebView : routes.contests}
          className={classes.backButton}
        >
          <ArrowBackIcon />
          <span className={classes.backButtonLabel}>Back to Contests</span>
        </Link>
      </Box>

      <Container classes={{ root: classes.containerRoot }}>
        {contest?.logo_url && (
          <img src={contest?.logo_url} className={classes.banner} alt="" />
        )}
        <Box className={classes.buttonBox}>
          <span className={classes.share}>Share</span>

          <TextField
            defaultValue={window.location.href}
            variant="outlined"
            className={classes.inputText}
            ref={inputRef}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <FileCopyIcon
                    className={classes.fileCopyIcon}
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      toast.success('Copied to Clipboard.');
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />

          <ContestFormLink
            contest={contest}
            style={{
              padding: '15px 30px',
              marginLeft: 20,
            }}
            onSuccess={() => contestsData?.refetch()}
            label="Play!"
          />
        </Box>
        <Box className={classes.containerBox}>
          <Box className={classes.left}>
            <Typography className={classes.title}>{contest?.name}</Typography>
            <Typography className={classes.description}>{contest?.full_description}</Typography>
            {enterDate && (
              <Typography className={classes.entries}>
                Entries due by: {enterDate}
              </Typography>
            )}
          </Box>
          {contest?.prizes_description && contest?.rules_description && (
            <Box className={classes.right}>
              {contest?.prizes_description && (
                <>
                  <Typography className={classes.title2}>Prizes</Typography>
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: contest?.prizes_description }}
                  />
                </>
              )}
              {contest?.rules_description && (
                <>
                  <Typography className={classes.title2}>Rules</Typography>
                  <Typography
                    className={classes.text}
                    dangerouslySetInnerHTML={{ __html: contest?.rules_description }}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};

ContestDetail.propTypes = {
  match: PropTypes.shape(),
  webView: PropTypes.bool,
};

export default ContestDetail;
